.outer-circle {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 0, 0, 0.3); /* دائرة حمراء شفافة كبيرة */
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-circle {
    width: 10px;
    height: 10px;
    background-color: red; /* دائرة حمراء صغيرة */
    border-radius: 50%;
}
